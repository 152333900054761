// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-route-preset_CD5BH{position:absolute;top:0;right:0;bottom:0;left:0}.landing-route-preset__item_hQdVK{width:340px}.landing-route-preset__banner_BV5U2{max-width:1110px;margin:0 auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-route-preset": `landing-route-preset_CD5BH`,
	"landing-route-preset__item": `landing-route-preset__item_hQdVK`,
	"landing-route-preset__banner": `landing-route-preset__banner_BV5U2`
};
export default ___CSS_LOADER_EXPORT___;
