import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import useGlobalLandingClass from 'web/src/modules/landings/useGlobalLandingClass';
import { LandingStyleAnimation, LandingStylePreset } from 'web/src/modules/landings/system/types';
import { item_1_1, item_1_2, item_1_3, item_2_1, item_2_2, item_2_3, item_3_1, item_3_2, item_3_3, squareImg, verticalImg } from 'web/src/modules/landings/system/storybookMocks';
import { LandingItemDirection, LandingItemImageSize } from 'web/src/modules/landings/system/components/LandingListItem/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import LandingList from 'web/src/modules/landings/system/components/LandingList/LandingList.vue';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import { LandingChevronDecor, LandingChevronKind } from 'web/src/modules/landings/system/components/LandingChevron/types';
import { SocialIconList } from 'web/src/modules/landings/system/components/LandingSocialLink/types';
import LandingSocialList from 'web/src/modules/landings/system/components/LandingSocialLink/LandingSocialList.vue';
import LandingBrand from 'web/src/modules/landings/system/components/LandingBrand/LandingBrand.vue';
import LandingBanner from 'web/src/modules/landings/system/components/LandingBanner/LandingBanner.vue';
import LandingListPanelTab from 'web/src/modules/landings/system/components/LandingListPanelTab/LandingListPanelTab.vue';
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import LandingHeroBorderedScreen from 'web/src/modules/landings/system/components/LandingHeroBorderedScreen/LandingHeroBorderedScreen.vue';
import { textAlign } from 'web/src/modules/landings/system/components/LandingList/types';
import LandingFooterPanel from 'web/src/modules/landings/system/components/LandingFooterPanel/LandingFooterPanel.vue';
import LandingFooter from 'web/src/modules/landings/system/components/LandingFooter/LandingFooter.vue';
import LandingHeroFullScreen from 'web/src/modules/landings/system/components/LandingHeroFullScreen/LandingHeroFullScreen.vue';
const title = 'App for <span>Android</span>';
const labelPrimary = 'Label primary. Use this label template for example in your design. You can use <span>strong text</span> if you need.';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingRoutePreset',
    setup (__props) {
        const { $translate } = useI18n();
        const { presetClass } = useGlobalLandingClass(LandingStylePreset.PRESET_1, LandingStyleAnimation.NO_ANIMATION);
        const tabIcon_1 = require('web/src/modules/landings/submodules/vip/images/tab-icon-1.svg');
        const tabIcon_2 = require('web/src/modules/landings/submodules/vip/images/tab-icon-2.svg');
        // eslint-disable-next-line import/order
        const tabIcon_3 = require('web/src/modules/landings/submodules/vip/images/tab-icon-3.svg');
        const listTab = [
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_1').value,
                icon: tabIcon_1,
                isActive: true
            },
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_2').value,
                icon: tabIcon_2,
                isActive: false
            },
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_3').value,
                icon: tabIcon_3,
                isActive: false
            }
        ];
        const itemProps = {
            index: 0,
            imageSize: LandingItemImageSize.SIZE_180,
            direction: LandingItemDirection.COLUMN_CENTER
        };
        const tabListContent = [
            {
                list: [
                    {
                        ...itemProps,
                        imageSrc: item_1_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_1_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_1_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_3').value
                    }
                ]
            },
            {
                list: [
                    {
                        ...itemProps,
                        imageSrc: item_2_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_2_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_2_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_3').value
                    }
                ]
            },
            {
                list: [
                    {
                        ...itemProps,
                        imageSrc: item_3_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_3_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        ...itemProps,
                        imageSrc: item_3_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_3').value
                    }
                ]
            }
        ];
        const itemArray = [
            {
                ...itemProps,
                imageSrc: item_1_1,
                title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_1').value,
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_1').value
            },
            {
                ...itemProps,
                imageSrc: item_1_2,
                title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_2').value,
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_2').value
            },
            {
                ...itemProps,
                imageSrc: item_1_3,
                title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_3').value,
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_3').value
            }
        ];
        const socialList = [
            {
                name: SocialIconList.INSTAGRAM,
                target: '',
                url: '1'
            },
            {
                name: SocialIconList.TELEGRAM,
                target: '',
                url: '1'
            },
            {
                name: SocialIconList.VK,
                target: '',
                url: '1'
            },
            {
                name: SocialIconList.FACEBOOK,
                target: '',
                url: '1'
            }
        ];
        const app1 = require('web/src/modules/landings/system/images/galaxy.png');
        const app2 = require('web/src/modules/landings/system/images/app-gallery.svg');
        const app3 = require('web/src/modules/landings/system/images/rustore.svg');
        const bannerData = {
            chevron: 'bonus',
            title: 'Фрибет <span>500₽</span> за установку приложения',
            // eslint-disable-next-line max-len
            label: 'Один из плюсов приложения – доступ к экслюзивным бонусам, которых нет на основном сайте. Например, фрибет номиналом 500 рублей, который мы дарим каждому, кто скачает наше приложение.',
            buttonLabel: 'Скачать',
            imageSrc: squareImg,
            appList: [
                {
                    src: app1,
                    link: 'example.com'
                }
            ]
        };
        const footerData = {
            title: 'Приложение для <span>Android</span>',
            buttonLabel: 'Скачать',
            iconName: IconName.DOWNLOAD,
            appsList: [
                {
                    src: app1,
                    link: 'example1.com'
                },
                {
                    src: app2,
                    link: 'example2.com'
                },
                {
                    src: app3,
                    link: 'example3.com'
                }
            ],
            isDarkBrand: true,
            copyrightList: [
                '© Leon Curacao N.V, 2007–2022',
                'Reg nbr 140186 - Address Schout Bij Nacht Doormanweg 40, Willemstad, Curacao'
            ]
        };
        const footerData2 = {
            copyrightList: [
                '© Leon Curacao N.V, 2007–2022'
            ]
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_unref(presetClass)]: true,
                    [_ctx.$style['landing-route-preset']]: true
                })
            }, [
                _createVNode(LandingFooterPanel, _normalizeProps(_guardReactiveProps(footerData2)), null, 16),
                _createVNode(LandingFooter, _normalizeProps(_guardReactiveProps(footerData)), null, 16),
                _createVNode(LandingHeroFullScreen, {
                    title: title,
                    label: labelPrimary,
                    chevron: "50 bnus",
                    "image-src": _unref(verticalImg),
                    "button-label": "Download"
                }, null, 8, [
                    "image-src"
                ]),
                _createVNode(LandingHeroBorderedScreen, {
                    title: title,
                    label: labelPrimary,
                    chevron: "50 bnus",
                    "image-src": _unref(verticalImg),
                    "button-label": "Download"
                }, null, 8, [
                    "image-src"
                ]),
                _createVNode(LandingButton, {
                    label: "button"
                }),
                _createVNode(LandingBrand),
                _createVNode(LandingSocialList, {
                    list: socialList
                }),
                _createVNode(LandingChevron, {
                    label: "Бонус 500 фрибетов",
                    kind: _unref(LandingChevronKind).SECONDARY,
                    decoration: _unref(LandingChevronDecor).DOUBLE_CIRCLE
                }, null, 8, [
                    "kind",
                    "decoration"
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createVNode(LandingChevron, {
                    label: "Бонус 500 фрибетов",
                    decoration: _unref(LandingChevronDecor).STAR
                }, null, 8, [
                    "decoration"
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _createVNode(LandingChevron, {
                    label: "Бонус 500 фрибетов",
                    decoration: _unref(LandingChevronDecor).CIRCLE
                }, null, 8, [
                    "decoration"
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createVNode(LandingChevron, {
                    label: "Бонус 500 фрибетов",
                    decoration: _unref(LandingChevronDecor).DOUBLE_CIRCLE
                }, null, 8, [
                    "decoration"
                ]),
                _createVNode(LandingList, {
                    title: "Our advantages",
                    label: "Discover a world of exclusive perks and unmatched benefits with our VIP program.",
                    list: itemArray,
                    "header-text-align": _unref(textAlign).LEFT
                }, null, 8, [
                    "header-text-align"
                ]),
                _createVNode(LandingBanner, _normalizeProps(_guardReactiveProps(bannerData)), null, 16),
                _createVNode(LandingListPanelTab, {
                    list: tabListContent,
                    "list-tabs": listTab
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingRoutePreset'
                ]
            ]);
        };
    }
});
