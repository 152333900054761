// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-hero-bordered-screen_FLyz_{width:100%;max-width:1440px;padding:24px 16px 60px;margin:0 auto}.landing-hero-bordered-screen__panel_UjExm{position:relative;display:flex;flex-direction:column;gap:32px;width:100%;padding:16px;background:var(--landingPanelBackgroundPrimary);border-radius:16px}.landing-hero-bordered-screen__background-image_a8ihu{position:absolute;top:0;right:0;bottom:0;left:0;background-repeat:no-repeat;background-size:100%;border-radius:16px}.landing-hero-bordered-screen__image_ZQNAD{width:100%}.landing-hero-bordered-screen__content_VgxFs{display:flex;flex-direction:column;gap:16px;width:100%}.landing-hero-bordered-screen__content-header_69gsw{display:flex;flex-direction:column;gap:8px}.landing-hero-bordered-screen__button_VlPkf{width:100%}@media(min-width:1024px){.landing-hero-bordered-screen_FLyz_{padding:32px 0}.landing-hero-bordered-screen__panel_UjExm{flex-direction:row;align-items:center;padding:0 0 0 7%;border-radius:24px}.landing-hero-bordered-screen__content_VgxFs{z-index:1;gap:16px;width:40%;padding:42px 0}.landing-hero-bordered-screen__content-footer_yLYuo{padding-top:36px}.landing-hero-bordered-screen__image_ZQNAD{width:60%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-hero-bordered-screen": `landing-hero-bordered-screen_FLyz_`,
	"landing-hero-bordered-screen__panel": `landing-hero-bordered-screen__panel_UjExm`,
	"landing-hero-bordered-screen__background-image": `landing-hero-bordered-screen__background-image_a8ihu`,
	"landing-hero-bordered-screen__image": `landing-hero-bordered-screen__image_ZQNAD`,
	"landing-hero-bordered-screen__content": `landing-hero-bordered-screen__content_VgxFs`,
	"landing-hero-bordered-screen__content-header": `landing-hero-bordered-screen__content-header_69gsw`,
	"landing-hero-bordered-screen__button": `landing-hero-bordered-screen__button_VlPkf`,
	"landing-hero-bordered-screen__content-footer": `landing-hero-bordered-screen__content-footer_yLYuo`
};
export default ___CSS_LOADER_EXPORT___;
