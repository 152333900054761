// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-footer-panel_W310D{padding:0 16px 160px}.landing-footer-panel__panel_vzcrJ{display:flex;flex-direction:column;gap:32px;align-items:center;padding:40px;background:var(--landingFooterPanelBackground);border-radius:20px}.landing-footer-panel__copyright_qWdS3{display:flex;flex-direction:column;gap:8px;align-items:center;font-family:var(--landingCopyrightFontFamily);font-size:var(--landingCopyrightFontSize);font-weight:var(--landingCopyrightFontWeight);line-height:var(--landingCopyrightLineHeight);color:var(--landingCopyrightColor);text-align:center}@media(min-width:1024px){.landing-footer-panel_W310D{padding:0 0 200px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-footer-panel": `landing-footer-panel_W310D`,
	"landing-footer-panel__panel": `landing-footer-panel__panel_vzcrJ`,
	"landing-footer-panel__copyright": `landing-footer-panel__copyright_qWdS3`
};
export default ___CSS_LOADER_EXPORT___;
